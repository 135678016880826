.container {
    width: 100%;
    height: 100vh;
    background-color: #f3f3f3;
    display: flex;
    justify-content: center;
    align-items: center;
}
.water {
    width: 30%;
    height: 15%;
    background-color: rgba(130, 56, 94, 1);
    border-radius: 50%;
    position: relative;
    box-shadow: inset 0 0 30px 0 rgba(0, 0, 0, 0.5), 0 4px 10px 0 rgba(0, 0, 0, 0.5);
    overflow: hidden;
}
.water:before,
.water:after {
    content: '';
    position: absolute;
    width: 110%;
    height: 85%;
    top: -25%;
    background-color: #f3f3f3;
}
.water:before {
    border-radius: 45%;
    background: rgba(255, 255, 255, 0.7);
    animation: wave 5s linear infinite;
}
.water:after {
    border-radius: 35%;
    background: rgba(255, 255, 255, 0.3);
    animation: wave 5s linear infinite;
}
@keyframes wave {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}
