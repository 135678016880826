.react-calendar {
    max-width: 100%;
    background: white;
    border: 2px solid #82385e;
    border-radius: 0.5em;
    font-family: 'Montserrat', sans-serif;
    line-height: 1.125em;
    box-sizing: border-box !important;
}
.react-calendar--doubleView {
    width: 700px;
}
.react-calendar__viewContainer {
    padding: 16px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
}
.react-calendar button:enabled:hover {
    cursor: pointer;
}
.react-calendar__navigation {
    display: flex;
    justify-content: space-between;
    padding: 16px 16px 0;
}
.react-calendar__navigation__label {
    color: #82385e;
    flex-grow: 0 !important;
}
.react-calendar__navigation button {
    background: none;
}
.react-calendar__navigation button:first-of-type,
.react-calendar__navigation button:last-of-type {
    display: none;
}
.react-calendar__navigation button:disabled {
    background-color: #f0f0f0;
}
.react-calendar__navigation .react-calendar__navigation__next-button {
    background-color: red;
    overflow: hidden;
    border-radius: 50%;
}
.react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: capitalize;
    padding-bottom: 1em;
}
.react-calendar__month-view__weekdays__weekday {
    font-size: 1em;
    color: #1f1f1f;
    opacity: 0.5;
    font-weight: 600;
}
.react-calendar__month-view__weekdays__weekday > * {
    text-decoration: none;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    font-weight: bold;
}
.react-calendar__month-view__days {
    display: grid !important;
    grid-template-columns: repeat(7, 1fr) !important;
    column-gap: 0.5em;
    row-gap: 0.8em;
}
.react-calendar__month-view__days__day--neighboringMonth {
    color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
    padding: 1em 0em;
    font-size: 0.8em;
}
.react-calendar .react-calendar__tile {
    max-width: 100%;
    padding: 0.15em;
    font-size: 1.125em;
    background: none;
    text-align: center;
    line-height: 1em;
    border: 1px solid transparent;
}
.react-calendar__tile:disabled {
    background-color: #f0f0f0;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    background-color: #e6e6e6;
}
.react-calendar__tile--now {
    background: #ffff76;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    background: #ffffa9;
}
.react-calendar__tile--hasActive {
    background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
    background: #a9d4ff;
}
.react-calendar__tile--active {
    border: 1px solid #82385e !important;
    background-color: #82385e20 !important;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: #1087ff;
}
.react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
}
